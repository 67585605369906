<template>
     <transition name="fade">
        <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50">
            <div class="fixed inset-0 bg-black opacity-50" @click="showModal = false"></div>
            <div class="bg-white p-6 rounded-md shadow-lg relative w-full max-w-md mx-auto">
                <button class="absolute top-2 right-2" @click="showModal = false">X</button>
                <p class="text-left text-gray-700 text-sm mt-4 mb-4">{{ $t('If you want to contact the team behind previsit.ai, please fill in the form below') }}</p>
                <form @submit.prevent="recaptcha" class="space-y-4">
                    <div class="text-left">
                        <label for="email" class="block text-gray-700">{{ $t('Email:') }}</label>
                        <input type="email" id="email" v-model="form.email" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
                    </div>
                    <div class="text-left">
                        <label for="subject" class="block text-gray-700">{{ $t('Subject:') }}</label>
                        <input type="text" id="subject" v-model="form.subject" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
                    </div>
                    <div class="text-left">
                        <label for="message" class="block text-gray-700">{{ $t('Message:') }}</label>
                        <textarea id="message" v-model="form.message" required class="mt-1 block w-full h-20 rounded-md border-gray-300 shadow-sm"></textarea>
                    </div>
                    <div class="text-right">
                    <PrimaryButton class="mt-4 text-right bg-blue-500">
                        {{ $t('Drop us a line') }}
                    </PrimaryButton>
                </div>
                </form>
            </div>
        </div>
     </transition>
     <div class="flex justify-right md:justify-center pb-2" id="try-it-out">
        <button v-if="appearance === 'button'" @click="showModal=true" class="bg-blue-600 text-white py-2 px-4 rounded text-center text-white">
            {{ $t(title) }}
        </button>
        <button v-else @click="showModal=true" class="md:inline-flex md:items-center md:px-3 md:py-2 md:border md:border-transparent md:text-sm md:leading-4 md:font-medium md:rounded-md md:text-gray-500 md:bg-white md:hover:text-gray-700 md:focus:outline-none md:transition md:ease-in-out md:duration-150">
            {{ $t(title) }}
        </button>
        <!-- {{  $t('Contact') }}-->
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useForm,usePage } from "@inertiajs/vue3";
import { useReCaptcha } from "vue-recaptcha-v3";
// import { trans } from 'laravel-vue-i18n';
import GuestLayout from "@/Layouts/GuestLayout.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
function openModal() {
    
    showModal.value = true;
  }
const showModal = ref(false);
const form = useForm({
    email: '',
    number: '',
    subject: '',
    message: '',
    captcha_token: null
});
const page = usePage();
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
const recaptcha = async () => {
    console.log("recaptcha");
  await recaptchaLoaded();
  form.captcha_token = await executeRecaptcha("login");
  submit();
};

const submit = () => {
    console.log("submitting");
  form.post(route("contact"), {
    onSuccess: (data) => {
        form.email = '';
        form.number = '';
        form.subject = '';
        form.message = '';
        form.captcha_token = null;
        showModal.value = false;
        console.log("success", data);
    },
    onFinish: () => form.reset("password", "password_confirmation"),
  });
};

const submitForm = () => {
    // Here you can send a POST request to your server with the form data
    // You can use the fetch API or a library like axios
};

const props = defineProps({
  title: {
    type: String,
    default: 'Contact'
  },
    appearance: {
        type: String,
        default: 'default'
    }
});

defineExpose({openModal})
</script>

<style scoped>
.fade-enter-active {
    transition: opacity 0.5s ease;  
}
.fade-leave-active {
  transition: opacity 1.0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>